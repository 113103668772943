if (!window.KLMR) { window.KLMR = {} }
window.KLMR.loadInsights = function (blockId, type, btn) {
  var url = '/api/latestInsights/getInsights?blockId=' + blockId + '&pageType=' + type;
  var $btn = $(btn);
  var $block = $btn.closest('.js-latest-insights-block');

  $.ajax({
    url: url,
    beforeSend: function () {
      $block.addClass('latest-insights-block--loading');
    }
  })
  .done(function (data) {
    console.log('-' + data.length + '-');
    if (data !== '') {
      $btn.siblings().removeClass('btn--filter-active');
      $btn.toggleClass('btn--filter-active');
      $block.find('.js-insights-list').empty().append(data);
    }
    $block.removeClass('latest-insights-block--loading');
  })
  .fail(function (err) {
    console.log(err);
    $block.removeClass('latest-insights-block--loading');
  });
}